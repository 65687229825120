<template>
  <div class="home">
    <div class="statusMain">
      <div class="imgCenter">
        <img src="../assets/cg.png" alt="">
      </div>
      <div class="statusCon">
        <h5>订单已支付成功</h5>
        <p>感谢您购买我们的产品/服务</p>
      </div>
      <div class="statusButtonList">
        <el-button @click="JumpToHome" type="primary">返回首页</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btnloading: false,  // 点击提交时，防止多次触碰，设定加载规则
      popstateLock: 0,//是否监听物理返回键
    }
  },
  mounted () {
    this.wxInit()
  },
  methods: {
    //指挥路由
    wxInit() {
      let initData = {
        action: "onIframeReady",
        displayStyle: "SHOW_CUSTOM_PAGE",
      };
      var initPostData = JSON.stringify(initData);
      parent.postMessage(initPostData, "https://payapp.weixin.qq.com");
    },
    JumpToHome() {
      let homePage = "https://ssmy.xinglian.info/online-shop/Home"
      var mchData = {
        action: "jumpOut",
        jumpOutUrl: homePage, //跳转的页面
      };
      var postData = JSON.stringify(mchData);
      parent.postMessage(postData, "https://payapp.weixin.qq.com");
    }
  },
}
</script>

<style scoped lang="scss">
.home::v-deep {
  .statusMain {
    position: relative;
    padding: 20px;
    .imgCenter {
      position: relative;
      margin: 15px auto;
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .statusCon {
      position: relative;
      h5 {
        font-size: 28px;
        text-align: center;
        line-height: 1.8;
        font-weight: 600;
      }
      p {
        line-height: 1.6;
        font-size: 16px;
        text-align: center;
      }
    }
    .statusButtonList{
      margin: 30px 0 0;
      position: relative;
      .el-button {
        width: 100%;
        //&:last-child {
        //  margin-left: 4%;
        //}
      }

    }
  }
}
</style>
